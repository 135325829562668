<template>
  <b-card no-body class="card-apply-job">
    <b-card-header class="pb-0 mb-0">
      <h4>Foto Perkembangan Kambing</h4>
      <p>{{goatData.length}}</p>
    </b-card-header>
    <span :class="goatData.length > 0 ? 'd-block' : 'd-none'">
      <b-carousel
      id="carousel-caption"
      controls
      indicators
      
      >
      <span v-for="photo in goatData" :key="photo.id">
        <b-carousel-slide
          :caption-html="`<span class='text-primary'><small>${photo.updated_at}<small/></span>`"
          class="card-img-overlay"
          text=""
          :img-src="photo.photo"
        />
      </span>
      
      </b-carousel> 
    </span>
    <span :class="goatData.length <= 0 ? 'd-block' : 'd-none'">
      <b-card-body class="pl-2 pr-2 mb-1">
        <b-card-text><small class="font-italic">Tidak ada foto ternak yang telah diunggah</small> </b-card-text>
      </b-card-body>
    </span>
  </b-card>
</template>

<script>
import {
  BCard, BCardText, BCardHeader,BCardBody, BCarousel, BCarouselSlide,
} from 'bootstrap-vue'


export default {
  components: {
    BCard,
    BCardText,
    BCardHeader,
    BCardBody,
    BCarousel,
    BCarouselSlide,
  },
  data(){
    return {
      goatData: {}
    }
  },
  mounted(){
    this.$http.get('/monitorings/' + this.$route.params.id + '/photo' ).then(response => {
      this.goatData = response.data.data
    })
  },
}
</script>
